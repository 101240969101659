.accordion {
  width: 100%;
  max-width: 768px;
  --bs-accordion-color: black !important;
  --bs-accordion-bg: #161618 !important;
  --bs-accordion-active-bg: #161618 !important;
  --bs-accordion-active-color: rgb(223, 223, 223) !important;
  --bs-accordion-btn-focus-border-color: rgb(223, 223, 223) !important;
  --bs-accordion-border: none;
  --bs-accordion-border-color: 161618 !important;
  --bs-accordion-btn-color: rgb(223, 223, 223) !important;
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgb(223, 223, 223)'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgb(223, 223, 223)'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
  --bs-accordion-btn-focus-box-shadow: none !important;
}

.accordian a {
  text-decoration: none;
  color: rgb(223, 223, 223) !important;
}

.accordian button {
  font-weight: 500 !important;
  font-size: 1.2rem;
}

.qa__title {
  width: 100%;
}

.qa__ul li {
  padding-left: 0;
  margin-top: 2px;
}