.text-container-highlight {
  background-color: #ff5d01;
  margin: auto;
  /* Center horizontally */
  height: auto;
  width: 70%;
  /* Adjust the width as needed */
  padding: 15px;
  border-radius: 8px;
}

.text-container-highlight h5 {
  color: white !important;
  text-align: center;
}