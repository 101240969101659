.about {
  flex-direction: column;
  margin-top: 3em;
  align-items: center;
}

.about__name {
  color: var(--clr-primary);
}

.mb-2 {
  margin: 5px 10px 5px 10px;
}

.about__gradient {
  /* Adjust the font size as needed */
  background: linear-gradient(45deg, #e38c4a, #c45959);
  /* Adjust gradient colors as desired */
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
}

.about__desc {
  font-size: 1.2rem;
  max-width: 48rem;
  font-weight: 500;
  color: linear-gradient(45deg, #333, #000);
  margin-bottom: 2rem;
  letter-spacing: -.01em;
  margin-left: auto;
  margin-right: auto;
}

.about__desc,
.about__contact {
  margin-top: .6em;
  text-align: center;
}

.about .link--icon {
  margin-right: 0.8em;
}

.about .btn--outline {
  margin-right: 1em;
}

@media (max-width: 768px) {
  .app .about {
    align-items: center;
    margin-top: 2em;
  }
}